import React, { useEffect } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';

import MovingText from '../MovingText';





import  pageTitle  from '../.././scss/helper/index';

export default function Home() {
  pageTitle('Antmark - Design, Software Company');

  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Instagram',
      links: 'http://www.instagram.com/antmark.in',
    },
    {
      name: 'Linked In',
      links: 'http://in.linkedin.com/company/antmark',
    },
  ];

  // FunFact Data
  const funfaceData = [
    {
      title: 'Global Clients',
      factNumber: '95',
    },
    {
      title: 'Projects Completed',
      factNumber: '238',
    },
    {
      title: 'Awards Won',
      factNumber: '02',
    },
    {
      title: 'Team Members',
      factNumber: '11',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      
      <Hero
        title="Driving the next wave of commerce<br>
with strategy, design and technology. "
subtitle="🏆 Top Innovative Company 2024 Award Winner"
        btnText="Get a Quote"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/bg3.jpg"
      />
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      <div className="container">
        <FunFact
          variant="cs-type1"
          subtitle={"Started in 2020"}
          title="We're proud of our journey"
          
    
          data={funfaceData}
        />
      </div>
      {/* End FunFact Section */}

      {/* Start Service Section */}
      <Spacing lg="100" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Services we can help you with"
                subtitle="Our Services"
                btnText="View All Services"
                btnLink="/service"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-8">
              <Div className="row">
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Website Development"
                    link="/contact"
                    src="/images/service_1.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Digital Marketing"
                    link="/contact"
                    src="/images/service_2.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Branding"
                    link="/contact"
                    src="/images/service_3.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
                <Div className="col-lg-3 col-sm-6">
                  <Card
                    title="Software Development"
                    link="/contact"
                    src="/images/service_4.jpeg"
                    alt="Service"
                  />
                  <Spacing lg="0" md="30" />
                </Div>
                <Div className="col-lg-3 col-sm-6 cs-hidden_mobile"></Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}
{/* Start MovingText Section */}
<Spacing lg="125" md="70" />
      <MovingText text="Delivering cost effective and scalable solutions to our clients across the globe." />
      
      {/* End MovingText Section */}
     {/* Start Why Choose Section */}
<Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_7.jpg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Why Antmark?"
              subtitle=""
              btnText="Know more"
                btnLink="/about"
            >
              <Spacing lg="10" md="10" /><Div className="cs-separator cs-accent_bg"></Div>
              <p className="cs-m0" style={{ fontSize: 18 }}>
              <br/>At Antmark, we believe in providing top-quality services that exceed our clients' expectations. We take pride in our work and strive to deliver the best results possible. We are dedicated to staying up-to-date with the latest trends and technologies and are committed to providing our clients with the most effective solutions possible.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0" style={{ fontSize: 18 }}>
              We believe in building strong relationships with our clients. We work closely with them to ensure that their needs are met and that they are completely satisfied with the work we do. We believe in transparency and honesty and work hard to earn the trust of our clients.
              </p>
              
              
              
            </SectionHeading>
          </Div>
        </Div>
      </Div><Spacing lg="125" md="70" />
      {/* End Why Choose Section */}

     

    
      {/* Start Team Section 
      <Spacing lg="125" md="80" />
      <Div className="container">
        <SectionHeading
          title="Our Awesome <br/>Team Members"
          subtitle="We're growing rapidly"
          variant="cs-style1"
        />
        <Spacing lg="75" md="45" />
        <TeamSlider />
      </Div>
      <Spacing lg="85" md="45" />
      {/* End Team Section */}

     

      

      

      
      
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss to make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg_31.jpg"
        />
      </Div>
      {/* End CTA Section */}
    </>
  );
}
